<template lang="pug">
  .wrapper(v-if="showSSFrame")
    #idensic(:class="{ hidden: docsSubmitted || (user.registration_country_id && user.is_country_restricted) }")
    .restricted
      p(v-if="user.registration_country_id && user.is_country_restricted") {{ $t('bannedCountries.KYCSplash') }}
    v-dialog(v-model="showKYCModal", persistent, fullscreen)
      v-layout.dialog(justify-center)
        v-icon.close(size="40", @click="cancelAge") mdi-close
        v-row.dialog_main(no-gutters, align="center")
          v-col
            .title {{ $t('common.verification') }}
            .description {{ $t('messages.warn.ageConfirmText') }}
            v-row.mt-5
              v-col
                v-btn(@click="confirmAge") {{ $t('actions.confirmAgeKYC') }}
              v-col
                v-btn.submit.shadowed(text, @click="cancelAge") {{ $t('actions.confirmUnderageKYC') }}
  .info_blc(v-else)
    .unconfirmed(v-if="!emailConfirmed")
      SettingsKycIntro(:emailConfirmed="emailConfirmed")
      ConfirmationEmailResend
    .blocked(v-if="user.kyc_status === 2")
      .wrapper(v-if="user.is_kyc_banned")
        h1 {{ $t('verification.blockedTitle') }}
        p(v-html="$t('verification.blockedDesc')")
      .wrapper(v-else)
        .suspended
          h1 {{ $t('verification.suspendedTitle') }}
          p {{ $t('verification.suspendedDesc1') }}
          .suspended_comment(v-if="user.kyc_moderation_comment")
            span {{ user.kyc_moderation_comment }}
          p {{ $t('verification.suspendedDesc2') }}
          v-btn(@click="retakingKYC = true") {{ $t('verification.retakeBtn') }}
    .passed(v-if="user.kyc_status === 3")
      .passed_cover
        .wrapper
          h1(v-html="$t('verification.passedTitle')")
          p(v-html="$t('verification.passedDesc')")
          v-layout(align-center)
            v-btn.green(@click="signinAndRedirect(hrefs.buyCrypto)") {{ $t('thankYouPage.crypto.button') }}
            svgVisaMasterCard
      .wrapper
        h2 {{ $t('verification.instantDeposit') }}
        KickExDepositCoins(@coinClick="signinAndRedirect(hrefs.deposit + $event)")
        h3 {{ $t('thankYouPage.mainTitle1') }}
        KickExLinks(@linkClick="signinAndRedirect")
    .docs_sent(v-if="user.kyc_status === 4 || docsSubmitted")
      .wrapper
        h1(v-html="$t('verification.docsSentTitle')")
        p(v-html="$t('verification.docsSentDesc')")
        .outlined(@click="signinAndRedirect(hrefs.markets)") {{ $t('thankYouPage.mainLinks.2.btn')}}
        .outlined(@click="signinAndRedirect(hrefs.funds)") {{ $t('verification.funds')}}
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import snsWebSdk from '@sumsub/websdk';
import store from '@/store/store';
import { SET_REDIRECT_URL_KYC } from '@/store/modules/user/types';
import SettingsKycIntro from '@/components/SettingsKycIntro';
import ConfirmationEmailResend from '@/components/ConfirmationEmailResend';
import CONSTANTS from '@/constants';
import KickExDepositCoins from '@/components/KickExDepositCoins';
import KickExLinks from '@/components/KickExLinks';
import SvgPlus from '@/components/svg/SvgPlus';
import SvgVisaMasterCard from '@/components/svg/SvgVisaMasterCard';
import sendAnalytics from '@/analytics';

export default {
  components: {
    SettingsKycIntro,
    ConfirmationEmailResend,
    KickExDepositCoins,
    KickExLinks,
    SvgPlus,
    SvgVisaMasterCard
  },
  props: {
    emailConfirmed: { type: Boolean, required: true }
  },
  data() {
    return {
      date: null,
      menu: false,
      has18Year: false,

      kycUserExternalId: '',
      kycToken: '',
      clientId: '',
      applicant_id: '',
      languages: CONSTANTS.LANGUAGES,
      showKYCModal: false,
      ageConfirmed: true,
      restrictedCountries: [],
      docsSubmitted: false,
      retakingKYC: false
    };
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters('user', [
      'buyCryptoHref'
    ]),
    showSSFrame() {
      return this.emailConfirmed
        && !this.docsSubmitted
        && (this.user.kyc_status === 0
        || this.user.kyc_status === 1
        || (this.user.kyc_status === 2 && this.retakingKYC));
    },
    hrefs() {
      return {
        markets: `${process.env.VUE_APP_KICKEX}/${this.$i18n.locale}/markets`,
        funds: `${process.env.VUE_APP_KICKEX}/${this.$i18n.locale}/funds`,
        buyCrypto: `${process.env.VUE_APP_KICKEX}/${this.$i18n.locale}/${this.buyCryptoHref}`,
        deposit: `${process.env.VUE_APP_KICKEX}/${this.$i18n.locale}/funds/deposits/`
      };
    },
    redirectUrlAfterKyc() {
      return this.$store.state.user.redirectUrlAfterKyc;
    }
  },
  methods: {
    signinAndRedirect(redirectUrl, serviceId = 3) {
      this.$store.dispatch('user/signinService', { serviceId, redirectUrl });
    },
    setBirthday(date) {
      const tmpDateOf18 = new Date();
      tmpDateOf18.setFullYear(tmpDateOf18.getFullYear() - 18);
      this.has18Year = tmpDateOf18.getTime() - new Date(date).getTime() >= 0;
      this.$refs.menu.save(date);
    },
    cancelAge() {
      this.$router.push({ name: 'Settings' });
    },
    confirmAge() {
      this.showKYCModal = false;
      this.ageConfirmed = true;
      sendAnalytics.KYC18yo();
    },
    launchWebSdk() {
      const lang = this.languages.find(i => i.id === this.user.language_id);
      const snsWebSdkInstance = snsWebSdk.init(
        this.kycToken,
        // token update callback, must return Promise
        // Access token expired
        // get a new one and pass it to the callback to re-initiate the WebSDK
        () => this.getKYCSession()
      )
        .withConf({
          lang: (lang && lang.val) || 'en',
          email: this.user.email,
          theme: 'light',
          externalUserId: this.kycUserExternalId,
          excludedCountries: this.restrictedCountries,
          uiConf: {
            steps: {
              SELFIE: { videoRequired: 'liveness' }
            }
          }
        })
        .on('idCheck.onApplicantSubmitted', payload => {
          console.log('WebSDK onMessage', 'idCheck.onApplicantSubmitted', payload);
          this.docsSubmitted = true;
          sendAnalytics.KYCIdentityDocument();
        })
        .on('idCheck.onApplicantStatusChanged', async payload => {
          console.log('WebSDK onMessage', 'idCheck.onApplicantStatusChanged', payload);

          if (payload.reviewStatus === 'completed' && this.redirectUrlAfterKyc) {
            const { attributes } = await this.$store.dispatch('user/getUser');

            if (attributes.kyc_status === 3) {
              sendAnalytics.KYCFinal();
              window.location = this.redirectUrlAfterKyc;
            }
          }
        })
        .on('idCheck.onError', error => {
          console.error('onError', error);
        })
        .build();

      snsWebSdkInstance.launch('#idensic');
    },
    async getKYCSession() {
      const session = await store.dispatch('user/getKYCSession');
      this.kycUserExternalId = session.external_user_id;
      this.kycToken = session.token;
      this.clientId = session.client_id;
      this.applicant_id = session.applicant_id;

      return Promise.resolve(session.token);
    }
  },
  watch: {
    async ageConfirmed(val) {
      if (val) {
        await this.getKYCSession();
        this.launchWebSdk();
      }
    },
    async retakingKYC(val) {
      if (val) {
        await this.getKYCSession();
        this.launchWebSdk();
      }
    }
  },
  beforeRouteEnter: async (to, from, next) => {
    if (process.env.VUE_APP_KYC !== 'true' || !store.state.user.registration_country_id) {
      next('/settings/personal');
      return;
    }

    if (to.query.redirectURL) {
      store.commit(`user/${SET_REDIRECT_URL_KYC}`, to.query.redirectURL);
    }

    if (store.state.user.kyc_status === 3) {
      next();
      return;
    }

    if (store.state.user.is_email_confirmed && !store.state.user.is_country_restricted) {
      store.dispatch('user/getRestrictedCountries')
        .then(countries => {
          next(vm => {
            vm.restrictedCountries = countries.map(i => i.alpha_3_code);

            if (store.state.user.kyc_status === 0 || store.state.user.kyc_status === 1) {
              vm.showKYCModal = true;
              vm.ageConfirmed = false;
            }
          });
        })
        .catch(() => next('/settings/personal'));
    } else {
      next();
    }
  }
};
</script>

<style scoped lang="scss">
  @import '../assets/scss/variables';

  .wrapper {
    padding-top: 95px;
    padding-bottom: 40px;
  }

  #idensic {
    min-height: calc(100vh - 290px);

    &.hidden { display: none; }
  }

  .verified {
    text-align: left;
    max-width: 514px;
    margin: 0 auto;
    color: $sub-black;

    &__header {
      font-weight: bold;
      font-size: 35px;
      line-height: 40px;
      margin-bottom: 24px;
    }

    &__text {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 24px;
    }

    &__rejection_reason {
      color: $error-color;
      margin-bottom: 24px;
    }

    .main_link { margin-bottom: 20px; }
  }

  .restricted {
    max-width: 514px !important;
    margin: 0 auto;
    font-size: 20px;
    text-align: left;
  }

  .suspended {
    max-width: 450px;

    h1 {
      font-size: 35px !important;
      line-height: 1.1;
    }

    p { margin-bottom: 40px; }

    &_comment {
      background: $white-transparent-60;
      padding: 32px;
      text-align: center;
      margin-bottom: 35px;

      span {
        display: inline-block;
        max-width: 180px;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .v-btn { padding: 21px 75px !important; }
  }

  .info_blc {
    text-align: left;
    line-height: 1.3;

    h1,
    h2 {
      font-size: 40px;
      margin-bottom: 20px;
    }

    h3 { font-size: 36px; }

    p {
      font-size: 20px;
      line-height: 1.8;
      font-weight: 500;
      max-width: 700px;
    }
  }

  .unconfirmed {
    padding-top: 80px;
    min-height: calc(100vh - 155px);
  }

  .passed {
    &_cover {
      background-image: linear-gradient(360deg, $white -2.17%, $avatar-gradient-to 100%), url('../assets/bg_success.jpg');
      background-position: right top;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .wrapper:not(:first-child) { padding-top: 0; }

    h1 { margin-bottom: 6px; }

    p { max-width: 400px; }

    .v-btn.green {
      font-size: 14px !important;
      padding: 15px 40px !important;
      font-weight: 700;
      margin-right: 70px;
    }

    img { margin-right: 25px; }

    .layout { margin-bottom: 80px; }
  }

  .docs_sent {
    background: $bg-gradient-1, $bg-gradient-2, $bg-gradient-3, url('../assets/bg_kyc.jpg');
    background-position: right top;
    background-repeat: no-repeat;
    min-height: calc(100vh - 155px);

    ::v-deep iframe { display: none !important; }

    .outlined {
      cursor: pointer;
      display: inline-block;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      text-decoration: none;
      border-radius: 2px;
      min-height: 66px;
      min-width: 260px;
      color:  $green;
      border: 2px solid $green;
      padding: 23px;
      opacity: .6;
      margin-bottom: 20px;

      &:not(:last-child) { margin-right: 25px; }
    }
  }

  .blocked {
    min-height: calc(100vh - 155px);
    background: linear-gradient(180deg, $white-3 0%, $avatar-gradient-to 179.2%), url('../assets/authPrometheus.jpg');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;

    .wrapper { padding: 150 0; }
  }

  .dialog {
    background: $white;
    text-align: left;
    padding: 40px 50px;
    height: 100%;
    position: relative;

    @media screen and (max-width: 600px) { padding: 20px 25px; }

    &_main { max-width: 550px; }

    .close {
      position: absolute;
      top: 40px;
      right: 40px;
    }

    .title {
      margin-bottom: 20px;
      font-size: 2.25rem !important;
      font-weight: bold;
    }

    .description {
      padding: 0;
      margin-bottom: 16px;
    }

    .v-input {
      margin-bottom: 20px;
    }

    ::v-deep .v-input__append-inner {
      margin: 0 !important;

      img {
        height: 24px;
        max-width: 24px;
      }
    }

    .passport {
      width: 100%;
      max-height: 270px;
      margin-bottom: 40px;
      box-shadow: 0px 8px 15px $passport-shadow;
      border-radius: 6px;
    }

    .v-btn {
      width: 100%;

      &.submit {
        color: $blue !important;
        background: none !important;
        border: 1px solid transparent;
        margin-bottom: 20px;
      }
    }
  }

  .confirm_age_btn,
  .cancel_age_btn {
    width: 100%;
    padding: 10px 30px !important;
    margin: 10px auto auto;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all .3s $easing;
  }

  .fade-leave-to,
  .fade-enter {
    opacity: 0;
  }

  @media screen and (max-width: 600px) {
    h1,
    h2,
    h3 {
      font-size: 30px !important;
    }

    .wrapper { padding-top: 40px; }

    .passed {
      &_cover {
        background: linear-gradient(180deg, $white-2 48.78%, $avatar-gradient-to 100%), linear-gradient(0deg, $white, $white);
      }

      .wrapper { padding-bottom: 0; }

      .v-btn.green { margin-right: 20px; }
    }

    .docs_sent,
    .blocked {
      padding-top: 0;
      background: linear-gradient(180deg, $white-2 48.78%, $avatar-gradient-to 100%), linear-gradient(0deg, $white, $white);

      .outlined {
        min-width: 100%;

         &:not(:last-child) { margin-right: 0; }
      }
    }

    .blocked .wrapper {
      padding-top: 50px;
    }
  }

  .date { cursor: pointer; }
</style>
